import React from 'react'
import Container from '../layout/container'
import PageContainer from './page-container'
import './imprint.scss'

const Imprint = () => (
  <PageContainer>
    <div className="imprint">
      <h3>Imprint & Legal</h3>
      <div className="imprint-container">
        <div className="imprint-content">
          <div className="title">EU-Streitschlichtung</div>
          <div className="content">
            <p>
              Die Europäische Kommission stellt eine Plattform zur
              Online-Streitbeilegung (OS) bereit:
            </p>

            <p>
              <a href="https://ec.europa.eu/consumers/odr.">
                https://ec.europa.eu/consumers/odr.
              </a>
            </p>
            <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
          </div>
          <div className="title">
            Verbraucherstreitbeilegung/Universalschlichtungsstelle
          </div>
          <div className="content">
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </div>
          <div className="title">Haftung für Inhalte</div>
          <div className="content">
            <p>
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
              Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
              verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
              jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu überwachen oder nach Umständen zu forschen, die
              auf eine rechtswidrige Tätigkeit hinweisen.
            </p>
            <p>
              Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
              Informationen nach den allgemeinen Gesetzen bleiben hiervon
              unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
              Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
              Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
              diese Inhalte umgehend entfernen.
            </p>
          </div>
          <div className="title">Haftung für Links</div>
          <div className="content">
            <p>
              Unser Angebot enthält Links zu externen Websites Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar.
            </p>
            <p>
              Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
              jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
              zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
              derartige Links umgehend entfernen.
            </p>
          </div>
        </div>
        <div className="imprint-box">
          <div className="imprint-box-content">
            <div className="title">Angaben gemäß § 5 TMG:</div>
            <p>
              <strong>
                DARC EXP GmbH <br /> Daimlerstraße 39 <br /> 73037 Göppingen
              </strong>
            </p>
            <div className="title">
              Steuernummer: <strong>63 001 06597</strong>
            </div>

            <p>
              <a href="mailto:info@darc-exp.com">info@darc-exp.com</a>
            </p>
            <div className="title">Geschäftsführer:</div>
            <p>
              <strong>Tom Konecny</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </PageContainer>
)

export default Imprint

import React from 'react'
import './container.scss'

const Container = props => (
  <div className={`${props.fullWidth ? 'full-width' : ''} container`}>
    {props.children}
  </div>
)

export default Container

import React from 'react'
import { Helmet } from 'react-helmet'

import Header from '../components/header/header'
import Imprint from '../components/pages/imprint'
import Footer from '../components/layout/footer'
import CookieBox from '../components/cookie/cookie-box'
const ImprintPage = () => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>DARC-EXP | Imprint & Legal</title>
        <body className="imprint" />
      </Helmet>
      <div className="imprintpage">
        <Header darkTheme />
        <CookieBox />
        <Imprint />
        <Footer />
      </div>
    </>
  )
}

export default ImprintPage

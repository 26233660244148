import React from 'react'

import './page-container.scss'

const PageContainer = props => (
  <div className="page-container">
    <div className="page-container-content">{props.children}</div>
  </div>
)

export default PageContainer
